import { OrdersFilterSearch } from '../../../../pages/orders-page/types'
import { Box } from '../../../../shared/uikit/box'
import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Icon } from '../../../../shared/uikit/icon'
import { InputDate } from '../../../../shared/uikit/input-date'
import { Select } from '../../../../shared/uikit/select'
import { Text } from '../../../../shared/uikit/text'
import { RentAreaListItem } from '../../../rent-area/types'
import {
  ORDER_STATUS_OPTIONS,
  ORDERS_FILTER_FORM_DEFAULT_VALUES,
} from '../../constants'
import {
  parseFilterFormValuesToFilterSearch,
  parseFilterSearchToFormValues,
} from '../../lib'
import { OrdersFilterFormValues, OrderType } from '../../types'

import s from './desktop-filter-form.module.scss'

interface DesktopFilterFormProps {
  filter: OrdersFilterSearch
  rentAreas: RentAreaListItem[]
  projects: RentAreaListItem['project'][]
  orderTypes: OrderType[]
  onChangeFilter: (filter: OrdersFilterSearch) => void
}

export const DesktopFilterForm = ({
  filter,
  projects,
  rentAreas,
  orderTypes,
  onChangeFilter,
}: DesktopFilterFormProps) => {
  // const filterCollapse = useOpenable(true)
  const vs = parseFilterSearchToFormValues({
    filter,
    projects,
    rentAreas,
    orderTypes,
  })

  const handleChange = (values: OrdersFilterFormValues) =>
    onChangeFilter(parseFilterFormValuesToFilterSearch(values))

  return (
    <div>
      {/* <Collapse isOpen={filterCollapse.isOpen} minHeight={40}> */}
      <Flex w="wrap" mb="24" g="12">
        <Box className={s.status}>
          <Select
            s="m"
            variant="white"
            type="multi"
            value={vs.statuses}
            onChange={(statuses) => handleChange({ statuses })}
            placeholder="Статус"
            options={ORDER_STATUS_OPTIONS}
            isClearable
          />
        </Box>
        <Box className={s.project}>
          <Select
            s="m"
            variant="white"
            type="multi"
            value={vs.projects}
            onChange={(projects) => handleChange({ projects })}
            placeholder="Проект"
            labelName="name"
            valueName="id"
            options={projects}
            isClearable
          />
        </Box>
        <Box className={s.rentAreas}>
          <Select
            s="m"
            variant="white"
            type="multi"
            value={vs.rentAreas}
            onChange={(rentAreas) => handleChange({ rentAreas })}
            placeholder="Помещения"
            labelName="unit"
            valueName="rentAreaId"
            options={rentAreas}
            isClearable
          />
        </Box>
        <Box className={s.type}>
          <Select
            s="m"
            variant="white"
            type="multi"
            value={vs.orderTypes}
            onChange={(orderTypes) => handleChange({ orderTypes })}
            placeholder="Тип заявки"
            labelName="name"
            valueName="id"
            options={orderTypes}
            isClearable
          />
        </Box>
        {/* <IconButton
          className={s.toggle}
          variant="tertiary"
          icon={filterCollapse.isOpen ? 'arrow-up-s' : 'arrow-down-s'}
          s="m"
          onClick={filterCollapse.toggle}
        /> */}
        <Flex className={s.sla} span="2" ai="center" g="6">
          <InputDate
            variant="white"
            value={vs.slaFrom}
            onChange={(slaFrom) => handleChange({ slaFrom })}
            placeholder="Срок исполнения, от"
            s="m"
          />
          <Text s="footnote">-</Text>
          <InputDate
            variant="white"
            value={vs.slaTo}
            onChange={(slaTo) => handleChange({ slaTo })}
            placeholder="Срок исполнения, до"
            s="m"
          />
        </Flex>
        <Flex className={s.created} span="2" ai="center" g="6">
          <InputDate
            variant="white"
            value={vs.createdFrom}
            onChange={(createdFrom) => handleChange({ createdFrom })}
            placeholder="Дата создания, от"
            s="m"
          />
          <Text s="footnote">-</Text>
          <InputDate
            variant="white"
            value={vs.createdTo}
            onChange={(createdTo) => handleChange({ createdTo })}
            placeholder="Дата создания, до"
            s="m"
          />
        </Flex>
        <Button
          onClick={() => handleChange(ORDERS_FILTER_FORM_DEFAULT_VALUES)}
          className={s.clear}
          variant="ghost"
          s="m"
          pre={<Icon name="close" color="primary-500" />}
        >
          Сбросить фильтр
        </Button>
      </Flex>
      {/* </Collapse> */}
    </div>
  )
}
