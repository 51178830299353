import { RentAreasFilterSearch } from '../../pages/rent-areas-page/types'

import { GetRentAreasFilter } from './api'
import { RENT_AREA_TYPES_OPTIONS } from './constants'
import { RentAreaListItem, RentAreasFilterFormValues } from './types'

interface ParseFilterSearchToFormValuesParams {
  filter: RentAreasFilterSearch
  projects: RentAreaListItem['project'][]
}

export const parseFilterSearchToFormValues = ({
  filter,
  projects,
}: ParseFilterSearchToFormValuesParams): RentAreasFilterFormValues => {
  return {
    projects: projects.filter((p) => filter.projects?.includes(p.id)),
    types:
      filter.types
        ?.map((t) => RENT_AREA_TYPES_OPTIONS.find((o) => o.value === t))
        .filter(Boolean) || [],
  }
}

export const parseFilterFormValuesToFilterSearch = (
  vs: RentAreasFilterFormValues,
): RentAreasFilterSearch => {
  return {
    projects: vs.projects?.map(({ id }) => id) || [],
    types: vs.types?.map(({ value }) => value) || [],
  }
}

export const parseFilterFormValuesToRequestDto = (
  vs: RentAreasFilterFormValues,
): GetRentAreasFilter => {
  return {
    destinationTypes: vs.types?.map(({ value }) => value),
    projectIds: vs.projects?.map(({ id }) => id),
  }
}
