import { RentAreasFilterSearch } from '../../../../pages/rent-areas-page/types'
import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Icon } from '../../../../shared/uikit/icon'
import { Select } from '../../../../shared/uikit/select'
import {
  RENT_AREA_TYPES_OPTIONS,
  RENT_AREAS_FILTER_FORM_DEFAULT_VALUES,
} from '../../constants'
import { parseFilterFormValuesToFilterSearch } from '../../lib'
import { RentAreaListItem, RentAreasFilterFormValues } from '../../types'

import s from './desktop-filter-form.module.scss'

interface DesktopFilterFormProps {
  projects: RentAreaListItem['project'][]
  values: RentAreasFilterFormValues
  onChangeFilter: (filter: RentAreasFilterSearch) => void
}

export const DesktopFilterForm = ({
  projects,
  values: vs,
  onChangeFilter,
}: DesktopFilterFormProps) => {
  const handleChange = (values: Partial<RentAreasFilterFormValues>) =>
    onChangeFilter(parseFilterFormValuesToFilterSearch({ ...vs, ...values }))

  return (
    <Flex mb="24" g="12">
      <Select
        s="m"
        variant="white"
        type="multi"
        valueName="id"
        labelName="name"
        placeholder="Проект"
        options={projects}
        value={vs.projects}
        onChange={(projects) => handleChange({ projects })}
        isClearable
      />
      <Select
        s="m"
        variant="white"
        type="multi"
        placeholder="Тип"
        options={RENT_AREA_TYPES_OPTIONS}
        value={vs.types}
        onChange={(types) => handleChange({ types })}
        isClearable
      />
      <Button
        onClick={() => handleChange(RENT_AREAS_FILTER_FORM_DEFAULT_VALUES)}
        className={s.clear}
        variant="ghost"
        s="m"
        pre={<Icon name="close" color="primary-500" />}
      >
        Сбросить фильтр
      </Button>
    </Flex>
  )
}
