import { RentAreasFilterFormValues } from './types'

export const RENT_AREA_LIST_QUERY_KEY = 'rent-area-list'
export const ALL_RENT_AREAS_QUERY_KEY = 'all-rent-areas'
export const RENT_AREA_PHOTO_QUERY_KEY = 'rent-area-photo'

export const RENT_AREA_MOP_TYPE = 'Mop'

export const RENT_AREA_TYPES = {
  [RENT_AREA_MOP_TYPE]: 'МОП',
  MultiPurpose: 'Помещение свободного назначения',
  Warehouse: 'Склад',
  Office: 'Офис',
  RetailSpace: 'Торговая площадь',
  ProductionComplex: 'Производственный комплекс',
  Apartments: 'Апартаменты',
}

export const RENT_AREA_TYPES_OPTIONS = Object.entries(RENT_AREA_TYPES).map(
  ([value, label]) => ({ label, value }),
)

export const RENT_AREAS_FILTER_FORM_DEFAULT_VALUES: RentAreasFilterFormValues =
  {
    projects: [],
    types: [],
  }

export const RENT_AREA_SORT_FIELDS = {
  createdAt: 'CreatedAt',
  rentArea: 'Unit',
  status: 'RentAreaStatus',
  type: 'DestinationType',
  project: 'ProjectName',
  client: 'Renter',
} as const
