export const NOTIFICATION_LIST_QUERY_KEY = 'notification-list'
export const NOTIFICATION_POPUP_LIST_QUERY_KEY = 'notification-popup-list'
export const NOTIFICATION_COUNT_QUERY_KEY = 'notification-count-list'

export const NOTIFICATION_LIST_SORT_FIELDS = {
  createdAt: 'CreatedAt',
} as const

export const NOTIFICATION_SOURCE_TYPES = {
  order: 'Order',
  news: 'News',
  faq: 'Faq',
  rentArea: 'RentArea',
  meter: 'Meter',
} as const
