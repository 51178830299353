import { getRouteApi } from '@tanstack/react-router'

import { RentAreaListSearch, RentAreasFilterSearch } from './types'

const { useSearch, useNavigate } = getRouteApi('/_layout-main/')

export const useRentAreasPage = () => {
  const { ...filter } = useSearch()
  const navigate = useNavigate()
  const handleChangeParams = (newSearch: RentAreaListSearch) => {
    void navigate({
      search: (prev) => ({ ...prev, ...newSearch }),
      replace: true,
    })
  }

  const handleChangeFilter = (filter: RentAreasFilterSearch) => {
    handleChangeParams(filter)
  }

  return {
    flags: {},
    data: { filter },
    actions: { handleChangeFilter },
  }
}
