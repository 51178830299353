import { z } from 'zod'

export const rentAreasFilterSearchSchema = z.object({
  types: z.array(z.string()).optional(),
  projects: z.array(z.string()).optional(),
})

export const rentAreasListParamsSchema = z
  .object({})
  .merge(rentAreasFilterSearchSchema)

export type RentAreaListSearch = z.infer<typeof rentAreasListParamsSchema>
export type RentAreasFilterSearch = z.infer<typeof rentAreasFilterSearchSchema>
