import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { parseDate } from '../../../../shared/lib/dates'
import { EstimatedPriceDetails } from '../../types'

interface PriceHistoryChartProps {
  data?: EstimatedPriceDetails['prices']
}

export const PriceHistoryChart = ({ data = [] }: PriceHistoryChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={142}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="35%" stopColor="#DAFFAD" stopOpacity={1} />
            <stop offset="107%" stopColor="#FFFFFF" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke="#D1DDE5" />
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => parseDate(value as string, 'MMM yy')}
        />
        <YAxis
          axisLine={{ stroke: '#D1DDE5' }}
          tickLine={{ stroke: '#D1DDE5' }}
          width={70}
          tickFormatter={(value) =>
            value
              ? value >= 1_000_000_000
                ? `${value / 1_000_000_000} млрд`
                : value >= 1_000_000
                  ? `${value / 1_000_000} млн`
                  : `${value / 1_000} тыс`
              : ''
          }
        />
        <Area
          isAnimationActive={false}
          dataKey="price"
          stroke="#9FE846"
          strokeWidth={3}
          fill="url(#gradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

// const data = [
//   {
//     name: 'янв',
//     price: 16_000_000,
//   },
//   {
//     name: 'фев',
//     price: 18_000_000,
//   },
//   {
//     name: 'мар',
//     price: 20_000_000,
//   },
//   {
//     name: 'апр',
//     price: 19_000_000,
//   },
//   {
//     name: 'май',
//     price: 24_000_000,
//   },
//   {
//     name: 'июн',
//     price: 26_000_000,
//   },
//   {
//     name: 'июл',
//     price: 21_000_000,
//   },
//   {
//     name: 'авг',
//     price: 23_000_000,
//   },
//   {
//     name: 'сен',
//     price: 27_000_000,
//   },
//   {
//     name: 'окт',
//     price: 25_000_000,
//   },
//   {
//     name: 'ноя',
//     price: 26_000_000,
//   },
//   {
//     name: 'дек',
//     price: 30_000_000,
//   },
// ]
