import { bffRequest } from '../../shared/api/bff-request'
import {
  ListResponse,
  PaginationRequestParams,
  SortingRequestParams,
} from '../../shared/types/api-types'

import { RentAreaListItem, RentAreaSortColumn } from './types'

export interface GetRentAreasFilter {
  destinationTypes?: string[]
  projectIds?: string[]
}

interface GetRentAreasRequest extends GetRentAreasFilter {
  pagination: PaginationRequestParams
  sorting?: SortingRequestParams<RentAreaSortColumn>
}

export const getRentAreas = (body: GetRentAreasRequest) =>
  bffRequest.post<ListResponse<RentAreaListItem>>(
    '/RentAreas/GetRentAreaMainInfos',
    body,
  )

export interface GetRentAreaPhotoRequest {
  rentAreaId: string
  photoFileName: string
}

export const getRentAreaPhoto = (params: GetRentAreaPhotoRequest) =>
  bffRequest
    .get<Blob>('/RentAreas/GetRentAreaPhoto', {
      params,
      responseType: 'blob',
    })
    .then((blob) => URL.createObjectURL(blob))
