import { Box } from '../../../../shared/uikit/box'
import { IconSimpleButton } from '../../../../shared/uikit/icon-simple-button'
import { Notification, NotificationProps } from '../notification'

import s from './notification-item.module.scss'

interface NotificationItemProps extends NotificationProps {
  onClickRead: () => void
}

export const NotificationItem = ({
  onClickRead,
  ...notificationProps
}: NotificationItemProps) => {
  return (
    <Box className={s.root} py="6" pr="20" rad="8">
      <Notification {...notificationProps} />
      {notificationProps.isNew && (
        <IconSimpleButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onClickRead()
          }}
          className={s.button}
          icon="check"
          s="24"
        />
      )}
    </Box>
  )
}
