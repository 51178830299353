import { getRouteApi } from '@tanstack/react-router'
import { useEffect, useMemo, useRef } from 'react'

import { DashboardDesktopFilterForm } from '../../entities/dashboard/components/dashboard-desktop-filter-form'
import { DashboardMobileFilterForm } from '../../entities/dashboard/components/dashboard-mobile-filter-form'
import { PriceHistoryChart } from '../../entities/dashboard/components/price-history-chart'
import { RentalIncomeChart } from '../../entities/dashboard/components/rental-income-chart'
import { useEstimatedPriceDetails } from '../../entities/dashboard/hooks/use-estimated-price-details'
import { useFinanceDetails } from '../../entities/dashboard/hooks/use-finance-details'
import { useFinanceSummary } from '../../entities/dashboard/hooks/use-finance-summary'
import {
  parseDashboardFilterSearchToFormValues,
  parseDashboardFormValuesToFilterRequest,
  parseDashboardFormValuesToFilterSearch,
} from '../../entities/dashboard/lib'
import { DashboardFilterFormValues } from '../../entities/dashboard/types'
import { useAllRentAreas } from '../../entities/rent-area/hooks/use-all-rent-areas'
import { PageHeader } from '../../shared/components/page-header'
import { useModal } from '../../shared/hooks/use-modal'
import { formatPrice } from '../../shared/lib/format-price'
import { Box } from '../../shared/uikit/box'
import { Card } from '../../shared/uikit/card'
import { Flex } from '../../shared/uikit/flex'
import { FullscreenModal } from '../../shared/uikit/fullscreen-modal'
import { Grid } from '../../shared/uikit/grid'
import { IconButton } from '../../shared/uikit/icon-button'
import { Text } from '../../shared/uikit/text'
import { ProfileBar } from '../../widgets/profile-bar/components/profile-bar'

import { DashboardSearch } from './types'

import s from './dashboard-page.module.scss'

const { useSearch, useNavigate } = getRouteApi('/_layout-main/dashboard/')

export const DashboardPage = () => {
  const { projects, rentAreas } = useAllRentAreas()

  const { ...filter } = useSearch()
  const navigate = useNavigate()

  const formValues = useMemo(
    () =>
      parseDashboardFilterSearchToFormValues({
        filter,
        projects,
        rentAreas,
      }),
    [filter, projects, rentAreas],
  )

  const filterRequest = useMemo(
    () => parseDashboardFormValuesToFilterRequest(formValues),
    [formValues],
  )

  const { financeSummary } = useFinanceSummary(filterRequest)
  const { financeDetails } = useFinanceDetails(filterRequest)
  const { estimatedPriceDetails } = useEstimatedPriceDetails(filterRequest)

  const handleChangeParams = (newSearch: DashboardSearch) => {
    void navigate({
      search: (prev) => ({ ...prev, ...newSearch }),
      replace: true,
    })
  }

  const handleChangeFilter = (filter: DashboardFilterFormValues) => {
    handleChangeParams(parseDashboardFormValuesToFilterSearch(filter))
  }

  const mobileFilterModal = useModal()

  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      const isFixed = buttonRef.current?.classList.contains(s.mobileFilterButton!) === true // prettier-ignore
      const makeFixed = () => buttonRef.current?.classList.add(s.mobileFilterButton!) // prettier-ignore
      const makeStatic = () => buttonRef.current?.classList.remove(s.mobileFilterButton!) // prettier-ignore

      if (window.scrollY > 72) {
        if (!isFixed) makeFixed()
      } else {
        if (isFixed) makeStatic()
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <Box px="20" px_md="0" pt="20" pt_md="0">
        <Flex jc="space-between" mb="20" mb_md="32">
          <Flex ai="center" ai_md="flex-start" jc="space-between" width="full">
            <PageHeader>Дашборд</PageHeader>
            <Box
              hidden={mobileFilterModal.isOpen}
              hidden_md
              className={s.buttonContainer}
            >
              <IconButton
                s="m"
                ref={buttonRef}
                icon="equalizer"
                variant="tertiary"
                onClick={mobileFilterModal.open}
              />
            </Box>
          </Flex>
          <Box hidden display_lg="block">
            <ProfileBar />
          </Box>
        </Flex>

        <Box hidden display_md="block" mt="24">
          <DashboardDesktopFilterForm
            values={formValues}
            onChange={handleChangeFilter}
            projects={projects}
            rentAreas={rentAreas}
          />
        </Box>

        <Grid mt="24" mt_md="32" cols="1" cols_md="2" g="8" g_md="16">
          <Card>
            <Card.Header>
              <Text s="sub-headline-bold" color="text-500">
                Прибыль
              </Text>
            </Card.Header>
            <Card.Body>
              <Text s="caption1" color="text-300">
                Чистая прибыль
              </Text>
              <Flex ai="center" g="12">
                <Text s="title1-bold">
                  {financeSummary
                    ? `${formatPrice(Math.floor(financeSummary.revenue))} ₽`
                    : '-'}
                </Text>
                <Text
                  s="caption1-bold"
                  color="green-500"
                  bgColor="base-50"
                  py="2"
                  px="4"
                  rad="16"
                >
                  {financeSummary
                    ? `${financeSummary.percent > 0 ? '+' : ''}${financeSummary.percent.toFixed(1)}%`
                    : '-'}
                </Text>
              </Flex>
            </Card.Body>

            <Card.Divider />

            <Card.Body>
              <Flex>
                <Box mr="32">
                  <Text s="caption1" color="text-300">
                    Доход
                  </Text>
                  <Text s="title3" mt="2">
                    {financeSummary
                      ? `${formatPrice(Math.floor(financeSummary.income))} ₽`
                      : '-'}
                  </Text>
                </Box>
                <Box>
                  <Text s="caption1" color="text-300">
                    Расход
                  </Text>
                  <Text s="title3" mt="2">
                    {financeSummary
                      ? `${formatPrice(Math.floor(financeSummary.expenses))} ₽`
                      : '-'}
                  </Text>
                </Box>
              </Flex>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <Text s="sub-headline-bold" color="text-500">
                Оценочная стоимость
              </Text>
            </Card.Header>
            <Card.Body>
              <Flex ai="center" g="12">
                <Text s="title3-bold">
                  {estimatedPriceDetails
                    ? `${estimatedPriceDetails.priceDiff > 0 ? '+' : ''}${formatPrice(Math.floor(estimatedPriceDetails.priceDiff))} ₽`
                    : '-'}
                </Text>
                <Text
                  s="caption1-bold"
                  color="green-500"
                  bgColor="base-50"
                  py="2"
                  px="4"
                  rad="16"
                >
                  {estimatedPriceDetails
                    ? `${estimatedPriceDetails.percentDiff > 0 ? '+' : ''}${estimatedPriceDetails.percentDiff.toFixed(1)}%`
                    : '-'}
                </Text>
              </Flex>
              <Box mt="12">
                <PriceHistoryChart data={estimatedPriceDetails?.prices} />
              </Box>
            </Card.Body>
          </Card>

          <Card span="1" span_md="2">
            <Card.Header>
              <Text s="sub-headline-bold" color="text-500">
                Арендная доходность
              </Text>
            </Card.Header>
            <Card.Body>
              {/* <Box
                display="inline-block"
                py="12"
                px="24"
                rad="12"
                bgColor="base-50"
              >
                <Text s="caption1-bold">Ноябрь 2024</Text>
                <Flex g="24" mt="16">
                  <Box>
                    <Flex ai="center" g="4">
                      <Box rad="half" bgColor="primary-100" p="4" />
                      <Text s="caption1" color="text-300">
                        Прибыль
                      </Text>
                    </Flex>
                    <Text s="body" mt="2">
                      100 000 ₽
                    </Text>
                  </Box>
                  <Box>
                    <Text s="caption1" color="text-300">
                      Доход
                    </Text>
                    <Text s="body" mt="2">
                      198 000 ₽
                    </Text>
                  </Box>
                  <Box>
                    <Flex ai="center" g="4">
                      <Box rad="half" bgColor="red-100" p="4" />
                      <Text s="caption1" color="text-300">
                        Расход
                      </Text>
                    </Flex>

                    <Text s="body" mt="2">
                      98 000 ₽
                    </Text>
                  </Box>
                </Flex>
              </Box> */}
              <Box pt="24" px="12" pb="12" rad="12" bgColor="base-50">
                <RentalIncomeChart data={financeDetails?.finances} />
              </Box>
            </Card.Body>
          </Card>
        </Grid>
      </Box>

      <FullscreenModal
        isOpen={mobileFilterModal.isOpen}
        onClose={mobileFilterModal.close}
      >
        <Flex dir="column" p="20" height="full">
          <Flex ai="center" jc="space-between" width="full">
            <PageHeader>Фильтры</PageHeader>
            <IconButton
              s="m"
              icon="close"
              variant="secondary"
              onClick={mobileFilterModal.close}
            />
          </Flex>

          <Box mt="20" gr="1">
            <DashboardMobileFilterForm
              values={formValues}
              onChange={handleChangeFilter}
              projects={projects}
              rentAreas={rentAreas}
              onClose={mobileFilterModal.close}
            />
          </Box>
        </Flex>
      </FullscreenModal>
    </>
  )
}
