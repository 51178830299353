import { RentAreasFilterSearch } from '../../../../pages/rent-areas-page/types'
import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Select } from '../../../../shared/uikit/select'
import {
  RENT_AREA_TYPES_OPTIONS,
  RENT_AREAS_FILTER_FORM_DEFAULT_VALUES,
} from '../../constants'
import { parseFilterFormValuesToFilterSearch } from '../../lib'
import { RentAreaListItem, RentAreasFilterFormValues } from '../../types'

interface MobileFilterFormProps {
  projects: RentAreaListItem['project'][]
  values: RentAreasFilterFormValues
  onChangeFilter: (filter: RentAreasFilterSearch) => void
}

export const MobileFilterForm = ({
  projects,
  values: vs,
  onChangeFilter,
}: MobileFilterFormProps) => {
  const handleChange = (values: Partial<RentAreasFilterFormValues>) =>
    onChangeFilter(parseFilterFormValuesToFilterSearch({ ...vs, ...values }))

  return (
    <Flex dir="column" g="12" height="full" jc="space-between">
      <Flex dir="column" g="12">
        <Select
          type="multi"
          valueName="id"
          labelName="name"
          placeholder="Проект"
          options={projects}
          value={vs.projects}
          onChange={(projects) => handleChange({ projects })}
          isClearable
        />
        <Select
          type="multi"
          placeholder="Тип"
          options={RENT_AREA_TYPES_OPTIONS}
          value={vs.types}
          onChange={(types) => handleChange({ types })}
          isClearable
        />
      </Flex>

      <Button
        variant="tertiary"
        s="l"
        onClick={() => handleChange(RENT_AREAS_FILTER_FORM_DEFAULT_VALUES)}
      >
        Сбросить фильтр
      </Button>
    </Flex>
  )
}
