import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Rectangle,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { useResponsive } from '../../../../shared/hooks/use-responsive'
import { parseDate } from '../../../../shared/lib/dates'
import { formatPrice } from '../../../../shared/lib/format-price'
import { Box } from '../../../../shared/uikit/box'
import { Flex } from '../../../../shared/uikit/flex'
import { Text } from '../../../../shared/uikit/text'
import { FinanceDetail } from '../../types'

import s from './rental-income-chart.module.scss'

const CURRENT_MONTH_ID = 'current-month'

interface RentalIncomeChartProps {
  data?: FinanceDetail[]
}

export const RentalIncomeChart = ({ data = [] }: RentalIncomeChartProps) => {
  const [minWidth, setMinWidth] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useResponsive('md')

  const width =
    data.length * 80 + 100 <= minWidth ? minWidth : data.length * 80 + 100

  useEffect(() => {
    const w = ref.current?.offsetWidth || 0
    setMinWidth(w)
  }, [])

  useLayoutEffect(() => {
    const currentMonthBar = document.getElementById(CURRENT_MONTH_ID)

    if (currentMonthBar) {
      currentMonthBar.scrollIntoView({
        inline: 'center',
      })
    }
  }, [data])

  return (
    <div ref={ref} className={s.wrapper}>
      <BarChart data={data} barSize={22} width={width} height={190}>
        <defs>
          <pattern
            id="patternIncome"
            patternUnits="userSpaceOnUse"
            width="10"
            height="10"
            patternTransform="rotate(35)"
          >
            <line
              x1="0"
              y="0"
              x2="0"
              y2="10"
              stroke="#DAFFAD"
              strokeWidth="10"
            />
          </pattern>
          <pattern
            id="patternExpense"
            patternUnits="userSpaceOnUse"
            width="10"
            height="10"
            patternTransform="rotate(35)"
          >
            <line
              x1="0"
              y="0"
              x2="0"
              y2="10"
              stroke="#FABFBB"
              strokeWidth="10"
            />
          </pattern>
        </defs>
        <CartesianGrid vertical={false} stroke="#D1DDE5" />
        <XAxis
          dataKey="startDate"
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => parseDate(value as string, 'MMM yy')}
        />
        <YAxis
          hide={isMobile}
          axisLine={{ stroke: '#D1DDE5' }}
          tickLine={{ stroke: '#D1DDE5' }}
          tickFormatter={(value) =>
            value
              ? value >= 1_000_000
                ? `${value / 1_000_000} млн`
                : `${value / 1_000} тыс`
              : ''
          }
          width={80}
        />
        <Bar dataKey="income" isAnimationActive={false}>
          {data.map((value, i) => {
            const d = new Date(value.startDate)
            const isCurrentMonth =
              d.getMonth() === new Date().getMonth() &&
              d.getFullYear() === new Date().getFullYear()

            return (
              <Cell
                key={`cell-${i}`}
                id={isCurrentMonth ? CURRENT_MONTH_ID : ''}
                radius={6}
                fill={
                  new Date(value.startDate) > new Date()
                    ? 'url(#patternIncome)'
                    : '#DAFFAD'
                }
              />
            )
          })}
        </Bar>
        <Bar dataKey="expenses" isAnimationActive={false}>
          {data.map((value, i) => (
            <Cell
              key={`cell-${i}`}
              radius={6}
              fill={
                new Date(value.startDate) > new Date()
                  ? 'url(#patternExpense)'
                  : '#FABFBB'
              }
            />
          ))}
        </Bar>
        <Tooltip
          // @ts-ignore
          content={<CustomTooltip />}
          cursor={<CustomCursor />}
          // active
          wrapperStyle={{ pointerEvents: 'auto' }}
        />
      </BarChart>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomCursor = (props: any) => {
  const { x, y, width, height, stroke } = props
  return (
    <Rectangle
      fill="#e4ebf080"
      stroke={stroke}
      x={x}
      y={y}
      width={width}
      height={height}
      pointerEvents="auto"
    />
  )
}

// @ts-ignore
// eslint-disable-next-line react/prop-types
const CustomTooltip = ({ active, payload, label: _ }) => {
  // eslint-disable-next-line react/prop-types
  if (active && payload && payload.length) {
    return (
      <Box rad="12" bgColor="base-50" p="12" className={s.tooltip}>
        <Flex dir="column" g="2">
          <Flex g="16" jc="space-between">
            <Flex ai="center" g="6">
              <Box rad="half" bgColor="primary-100" p="4" />
              <Text s="body" color="text-300">
                Доход
              </Text>
            </Flex>

            <Text s="body" mt="2">
              {/* eslint-disable-next-line react/prop-types */}
              {formatPrice(payload[0]?.payload?.income as number)} ₽
            </Text>
          </Flex>
          <Flex g="16" jc="space-between">
            <Flex ai="center" g="6">
              <Box rad="half" bgColor="red-100" p="4" />
              <Text s="body" color="text-300">
                Расход
              </Text>
            </Flex>

            <Text s="body" mt="2">
              {/* eslint-disable-next-line react/prop-types */}
              {formatPrice(payload[0]?.payload?.expenses as number)} ₽
            </Text>
          </Flex>
          <Flex g="16" jc="space-between">
            <Flex ai="center" g="6">
              <Box rad="half" p="4" />
              <Text s="body-bold" color="text-300">
                Прибыль
              </Text>
            </Flex>
            <Text s="body-bold" mt="2">
              {/* eslint-disable-next-line react/prop-types */}
              {formatPrice(payload[0]?.payload?.revenue as number)} ₽
            </Text>
          </Flex>
        </Flex>
      </Box>
    )
  }

  return null
}

// const data = [
//   {
//     name: 'янв',
//     income: 16_000_000,
//     expense: 5_000_000,
//   },
//   {
//     name: 'фев',
//     income: 18_000_000,
//     expense: 2_000_000,
//   },
//   {
//     name: 'мар',
//     income: 20_000_000,
//     expense: 2_000_000,
//   },
//   {
//     name: 'апр',
//     income: 19_000_000,
//     expense: 3_000_000,
//   },
//   {
//     name: 'май',
//     income: 24_000_000,
//     expense: 1_000_000,
//   },
//   {
//     name: 'июн',
//     income: 26_000_000,
//     expense: 6_000_000,
//   },
//   {
//     name: 'июл',
//     income: 21_000_000,
//     expense: 4_000_000,
//   },
//   {
//     name: 'авг',
//     income: 23_000_000,
//     expense: 5_000_000,
//   },
//   {
//     name: 'сен',
//     income: 27_000_000,
//     expense: 5_000_000,
//   },
//   {
//     name: 'окт',
//     income: 25_000_000,
//     expense: 3_000_000,
//   },
//   {
//     name: 'ноя',
//     income: 26_000_000,
//     expense: 4_000_000,
//   },
//   {
//     name: 'дек',
//     income: 30_000_000,
//     expense: 7_000_000,
//   },
//   {
//     name: 'янв',
//     income: 16_000_000,
//     expense: 5_000_000,
//   },
//   {
//     name: 'фев',
//     income: 18_000_000,
//     expense: 2_000_000,
//   },
//   {
//     name: 'мар',
//     income: 20_000_000,
//     expense: 2_000_000,
//   },
//   {
//     name: 'апр',
//     income: 19_000_000,
//     expense: 3_000_000,
//   },
//   {
//     name: 'май',
//     income: 24_000_000,
//     expense: 1_000_000,
//   },
// ]
