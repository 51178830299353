import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import { getRentAreas, GetRentAreasFilter } from '../../entities/rent-area/api'
import { RENT_AREA_LIST_QUERY_KEY } from '../../entities/rent-area/constants'

import { RENT_AREAS_COUNT_PER_PAGE } from './constants'

export const useInfiniteRentAreas = (
  filter: GetRentAreasFilter | undefined = {},
) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [RENT_AREA_LIST_QUERY_KEY, filter],
    queryFn: ({ pageParam }) =>
      getRentAreas({
        ...filter,
        pagination: {
          page: pageParam,
          countPerPage: RENT_AREAS_COUNT_PER_PAGE,
        },
      }),
    getNextPageParam: (lastPage) =>
      lastPage.pagination?.hasMore
        ? lastPage.pagination.currentPage + 1
        : undefined,

    initialPageParam: 1,
    placeholderData: keepPreviousData,
  })

  return { pages: data?.pages || [], ...rest }
}
