import { useEffect, useRef } from 'react'

import { DesktopFilterForm } from '../../entities/rent-area/components/desktop-filter-form'
import { MobileFilterForm } from '../../entities/rent-area/components/mobile-filter-form'
import { useAllRentAreas } from '../../entities/rent-area/hooks/use-all-rent-areas'
import { parseFilterSearchToFormValues } from '../../entities/rent-area/lib'
import { RentAreaList } from '../../features/rent-area-list/components/rent-area-list'
import { PageHeader } from '../../shared/components/page-header'
import { useModal } from '../../shared/hooks/use-modal'
import { Box } from '../../shared/uikit/box'
import { Flex } from '../../shared/uikit/flex'
import { FullscreenModal } from '../../shared/uikit/fullscreen-modal'
import { IconButton } from '../../shared/uikit/icon-button'
import { ProfileBar } from '../../widgets/profile-bar/components/profile-bar'

import { useRentAreasPage } from './use-rent-areas-page'

import s from './rent-areas-page.module.scss'

export const RentAreasPage = () => {
  const {
    data: { filter },
    actions: { handleChangeFilter },
  } = useRentAreasPage()
  const { projects } = useAllRentAreas()

  const values = parseFilterSearchToFormValues({
    filter,
    projects,
  })

  const buttonRef = useRef<HTMLButtonElement>(null)
  const mobileFilterModal = useModal()

  useEffect(() => {
    const handleScroll = () => {
      const isFixed = buttonRef.current?.classList.contains(s.mobileFilterButton!) === true // prettier-ignore
      const makeFixed = () => buttonRef.current?.classList.add(s.mobileFilterButton!) // prettier-ignore
      const makeStatic = () => buttonRef.current?.classList.remove(s.mobileFilterButton!) // prettier-ignore

      if (window.scrollY > 72) {
        if (!isFixed) makeFixed()
      } else {
        if (isFixed) makeStatic()
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <Box px="20" px_md="0" pt="20" pt_md="0">
        <Flex jc="space-between" mb="20" mb_md="32">
          <Flex ai="center" ai_md="flex-start" jc="space-between" width="full">
            <PageHeader>Помещения</PageHeader>
            <Box
              hidden={mobileFilterModal.isOpen}
              hidden_md
              className={s.buttonContainer}
            >
              <IconButton
                s="m"
                ref={buttonRef}
                icon="equalizer"
                variant="tertiary"
                onClick={mobileFilterModal.open}
              />
            </Box>
          </Flex>
          <Box hidden display_lg="block">
            <ProfileBar />
          </Box>
        </Flex>

        <Box hidden display_md="block" mb="24">
          <DesktopFilterForm
            projects={projects}
            values={values}
            onChangeFilter={handleChangeFilter}
          />
        </Box>

        <RentAreaList filterValues={values} />
      </Box>

      <FullscreenModal
        isOpen={mobileFilterModal.isOpen}
        onClose={mobileFilterModal.close}
      >
        <Flex dir="column" p="20" height="full">
          <Flex ai="center" jc="space-between" width="full">
            <PageHeader>Фильтры</PageHeader>
            <IconButton
              s="m"
              icon="close"
              variant="secondary"
              onClick={mobileFilterModal.close}
            />
          </Flex>

          <Box mt="20" gr="1">
            <MobileFilterForm
              projects={projects}
              values={values}
              onChangeFilter={handleChangeFilter}
            />
          </Box>
        </Flex>
      </FullscreenModal>
    </>
  )
}
