import { Link } from '@tanstack/react-router'

import { ROUTES } from '../../../../shared/constants/routes'
import { NOTIFICATION_SOURCE_TYPES } from '../../constants'
import { NotificationSourceType } from '../../types'

interface NotificationLinkProps {
  source: NotificationSourceType
  id: string
  children?: React.ReactNode
}

export const NotificationLink = ({
  id,
  source,
  children,
}: NotificationLinkProps) => {
  return source === NOTIFICATION_SOURCE_TYPES.order ? (
    <Link to={ROUTES.orderId} params={{ orderId: id }}>
      {children}
    </Link>
  ) : (
    children
  )
}
