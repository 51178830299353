import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { RentAreaCard } from '../../../../entities/rent-area/components/rent-area-card'
import { parseFilterFormValuesToRequestDto } from '../../../../entities/rent-area/lib'
import { RentAreasFilterFormValues } from '../../../../entities/rent-area/types'
import { BaseButton } from '../../../../shared/uikit/base-button'
import { Flex } from '../../../../shared/uikit/flex'
import { Grid } from '../../../../shared/uikit/grid'
import { Icon } from '../../../../shared/uikit/icon'
import { Loader } from '../../../../shared/uikit/loader'
import { Text } from '../../../../shared/uikit/text'
import { RENT_AREAS_COUNT_PER_PAGE } from '../../constants'
import { useInfiniteRentAreas } from '../../use-infinite-rent-areas'

import s from './rent-area-list.module.scss'

const FAKE_LIST = new Array(RENT_AREAS_COUNT_PER_PAGE).fill(null)

interface RentAreaListProps {
  filterValues: RentAreasFilterFormValues
}

export const RentAreaList = ({ filterValues }: RentAreaListProps) => {
  const { pages, fetchNextPage, isLoading, isFetched } = useInfiniteRentAreas(
    parseFilterFormValuesToRequestDto(filterValues),
  )

  const { ref, inView } = useInView({
    rootMargin: isFetched ? '500px' : '200px',
  })

  useEffect(() => {
    if (inView && isFetched) {
      void fetchNextPage()
    }
  }, [inView, fetchNextPage, isFetched])

  return (
    <>
      <Grid cols="1" cols_md="2" cols_lg="3" g="12" g_md="20">
        {isLoading &&
          pages.length === 0 &&
          FAKE_LIST.map((_, i) => <RentAreaCard.Skeleton key={i} />)}

        {pages.map((page) =>
          page.list?.map((rentArea) => (
            <RentAreaCard key={rentArea.rentAreaId} rentArea={rentArea} />
          )),
        )}

        {isFetched && (
          <a href="https://25-7.pro/" target="_blank" rel="noreferrer">
            <BaseButton className={s.button}>
              <Flex dir="column" g="8" ai="center">
                <Flex g="6">
                  <Text s="title3-bold">Добавить помещение</Text>
                  <Icon name="share-box" s="24" />
                </Flex>
                <Text s="caption1" color="text-300">
                  Управляющая компания 25/7
                </Text>
              </Flex>
            </BaseButton>
          </a>
        )}
      </Grid>

      {pages.at(-1)?.pagination?.hasMore && (
        <Flex width="full" jc="center" mt="16">
          <Loader.InBox color="primary-500" />
        </Flex>
      )}

      {pages.length > 0 && <div ref={ref} />}
    </>
  )
}
